<template>
  <div onboarding-progress v-if="structure && modelProxy">
    <ProgressTitle :structure="structure" :is-final="currentItem.final" v-if="title" />
    <ValidationObserver class="field-holder" ref="validator" v-slot="{ invalid }" v-if="modelProxy && currentItem && ready">
      <component :is="template" :model-info="modelProxy" :structure="structure" :item="currentItem" :invalid="invalid" />
      <div class="submit-holder" v-if="!currentItem.background">
        <p v-if="errorMsg" class="error">{{ errorMsg }}</p>
        <ProgressButton :button-name="buttonName" @click="confirm" :progress="progress" :disabled="!!structure.limitTime || structure.error || Boolean(structure.errorMsg) || invalid" :name="buttonTag" :class="buttonClass" />
      </div>
    </ValidationObserver>
    <component v-for="(item,id) in footerComponent" :key="id" :is="item" :model-info="modelProxy" :structure="structure" />
  </div>
</template>

<script>
import _ from 'lodash';
import { state } from '@shared/utils/storeUtils.mjs';
import { focusSelector, sleep } from '@shared/utils/commonUtils.mjs';
import { trackGtmEvent, trackSegment } from '@/plugins/tracking';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';
import CaptchaSwitch from '@/views/components/extra/CaptchaSwitch.vue';
import TabIndexContainer from '@/views/components/common/TabIndexContainer.vue';
import { kycLevel } from '@/constants/base/onboarding/kyc';
import { siteIds } from '@/constants/base/siteMap.mjs';
import {CAUSE_TYPES, routeNames} from '@/constants/base/onboarding/onBoardingSiteMap';

export default {
  name: 'OnboardingProgress',
  components: { TabIndexContainer, CaptchaSwitch, LimitTimer, ProgressButton, ProgressTitle },
  data() {
    return {
      disabled: true,
      structure: null,
      modelProxy: null,
      errorMsg: null,
      captcha: null,
      orderInfo: null,
      progress: false,
      isFinal: false,
      ready: false,

      fieldsBlockInfo: {
        [siteIds.GGPDE]: [{ confirmKyc: kycLevel.AgeVerified, fields: ['FirstName', 'LastName', 'MaidenName'] }, { confirmKyc: kycLevel.AgeVerified, fields: ['Nationality', 'DateOfBirth', 'Gender', 'PlaceOfBirth'] }],
        [siteIds.WSOPON]: [{ confirmKyc: kycLevel.PoiVerified, fields: ['FirstName', 'LastName'] }, { confirmKyc: kycLevel.PoiVerified, fields: ['Nationality', 'DateOfBirth', 'Gender'] }],
        [siteIds.GGPNL]: [{ fields: ['FirstName', 'LastName'] }, { confirmKyc: kycLevel.AgeVerified, fields: ['Nationality', 'NationalId', 'DateOfBirth', 'Gender', 'PlaceOfBirth'] }],
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    env: state('env', 'env'),
    routeNames: state('env', 'routeNames'),
    captchaType: state('env', 'captcha'),
    causes: state('env', 'causes'),
    widgetUrl: state('env', 'widgetUrl'),
    userInfo: state('user', 'userInfo'),
    restrict: state('query', 'restrict'),
    mandatory: state('query', 'mandatory'),
    selectedLang: state('query', 'selectedLang'),
    currentItem() {
      return this.structure?.list?.[this.step];
    },
    template() {
      const t = this.currentItem?.template;
      return t?.[this.site] || t?.default || t;
    },
    keys() {
      const t = this.currentItem?.keys;
      return t?.[this.site] || t?.default || t;
    },
    service() {
      return this.currentItem?.service[this.site] || this.currentItem?.service.default || this.currentItem?.service;
    },
    api() {
      return this.currentItem?.api;
    },
    action() {
      return this.currentItem?.action;
    },
    title() {
      const t = this.currentItem?.title;
      if (!t) return '';
      return t?.[this.site] || t?.default || t;
    },
    buttonTag() {
      const t = this.currentItem?.buttonTag;
      if (!t) return '';
      return t?.[this.site] || t?.default || t;
    },
    buttonClass() {
      const t = this.currentItem?.buttonClass;
      if (!t) return '';
      return t?.[this.site] || t?.default || t;
    },
    buttonName() {
      const t = this.currentItem?.buttonName;
      if (!t) return this.lastStep ? 'submit' : 'next';
      return t?.[this.site] || t?.default || t;
    },
    currentRoute() {
      return this.currentItem?.route;
    },
    footer() {
      return this.currentItem?.footer?.[this.site];
    },
    footerComponent() {
      return this.footer ? (_.isArray(this.footer) ? this.footer.map(o => o.template) : [this.footer.template]) : [];
    },
    isLocal() {
      return this.env === 'local';
    },
    isStageOrProd() {
      return ['stage', 'production'].includes(this.env);
    },
    isProd() {
      return this.env === 'production';
    },
    isComplete() {
      return this.$route.name === 'Complete';
    },
    captchaPass() {
      return this.currentItem?.captcha;
    },
    step() {
      return this.structure?.step;
    },
    lastStep() {
      return this.step >= this.structure?.list?.length - 1;
    },
  },
  watch: {
    $route: async function() {
      if (process.env.VUE_APP_ENV !== 'production') {
        console.groupCollapsed('%conboarding watch route info', 'color:yellow');
        console.log('fullPath', this.$route?.fullPath);
        console.log('path', this.$route?.path);
        console.log('name', this.$route?.name);
        console.log('params', this.$route?.params ? JSON.stringify(this.$route?.params) : '');
        console.log('query', this.$route?.query ? JSON.stringify(this.$route?.query) : '');
        console.log('meta', this.$route?.meta ? JSON.stringify(this.$route?.meta) : '');
        console.groupEnd();
      }

      if (this.$route.name === 'Replace') this.modelProxy.replace = true;
      else if (this.structure?.list?.length > 0) await this.reset();
    }
  },
  methods: {
    async confirm() {
      if (this.$route.name !== 'Complete') {
        await this.checkValidate();

        let captcha = {};
        if (this.captchaPass) captcha = await this.$onboarding.setRecaptcha(this.action);
        await this.apiRequest(captcha);
        this.structure.customSteps = [];
      }
    },
    async checkValidate() {
      return await this.$validate(this.$refs.validator, focusSelector('.invalid input'));
    },
    async apiRequest(captcha) {
      if (!this.api) {
        await this.nextStep();
      } else {
        this.progress = true;
        const r = await this.getApiResponse(captcha);
        this.progress = false;
        if (r?.key === 'limit') {
          this.structure.limitTime = +r.value;
          if ([routeNames.verifyMobileNumber, routeNames.verifyContactMobileNumber, routeNames.contactMobileNumber, routeNames.verifyEmail, routeNames.verifyContactEmail, routeNames.contactEmail].some(r => r === this.$route.name)) await this.nextStep();
        } else if (r?.error) {
          if (this.$onboarding.errorHandler(r, true)) return;

          this.structure.error = true;
          this.structure.errorMsg = r.key;
          this.structure.errorDesc = r.desc || r.desc2;
          this.structure.errorParams = r.CustomerErrorParameters;

          if (!this.isProd) {
            console.log(`Api Request Error : ${this.service}.${this.api}`, r);
            this.$toast(r.desc, { type: 'fail', translate: false });
          }
        } else {
          await this.nextStep();
        }
      }
    },
    /**
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(captcha) {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services[this.service][this.api]({ ...this.structure.model, ...captcha }); }
      catch (e) { return e; }
    },
    async nextStep(add = 1) {
      try {
        if (this.step === this.structure?.list?.length - 1) {
          this.replaceRouteName('Complete');
        } else {
          const nextStep = this.structure?.list?.[this.step + add];
          if (nextStep) this.replaceRouteName(nextStep.route, { limitTime: this.structure.limitTime }, this.$route.query);
          else this.replaceRouteName('Complete');
        }
      } catch (e) {
        console.log('error : nextStep', e);
      }
    },
    async createProxyController() {
      if (this.modelProxy) return;

      const self = this;
      this.modelProxy = new Proxy(this.structure.model, {
        async set(target, prop, value) {
          if(target[prop] === value) return;

          self.structure.error = false;
          self.structure.errorMsg = null;
          self.structure.errorDesc = null;
          self.structure.errorParams = null;
          self.errorMsg = null;

          switch (prop) {
            case 'step':
              await self.nextStep(value);
              break;
            case 'final':
              this.isFinal = value;
              break;
            case 'validate':
              value(await self.checkValidate());
              break;
            case 'replace':
              if (value) {
                await self.reset(true);
              }
              self.structure.model[prop] = false;
              break;
            default:
              target[prop] = value;
              self.structure.model[prop] = value;
          }
          return true;
        },
      });
      this.setBlockField();
    },

    setBlockField() {
      const blockFields = this.fieldsBlockInfo[this.site];
      if (blockFields) {
        this.modelProxy.blockFields = [];
        blockFields.forEach(o => {
          let disabled = true;
          if (o.confirmKyc) {
            const myId = Object.keys(kycLevel).findIndex(v => v === this.userInfo.KycStatus);
            const confirmId = Object.keys(kycLevel).findIndex(v => v === o.confirmKyc);
            disabled = myId >= confirmId;
          }
          if (disabled) this.modelProxy.blockFields.push(...o.fields.filter(v => this.modelProxy[v] && this.modelProxy[v].length));
        });
      }
    },

    async reset(replace) {
      if (this.changeRoute) return;
      this.ready = false;
      this.$block();

      if (replace) {
        await this.$onboarding.updateStructure(this.structure, true);
        this.setBlockField();
      }
      const model = await this.$onboarding.getModel(replace, this.$route.params);
      if (model?.error) return;
      // GGPPH 의 경우 TemporaryVerificationStatus가 Accepted, Expired일 경우는  OnboardingRouteStepAdaptor > appendRuleStep 를 진행하도록 함
      if (model?.list?.length === 0 || (this.site === siteIds.GGPPH && model?.list?.length === 0 && this.userInfo.TemporaryVerificationStatus === 'None')) {
        this.$unblock();
        await this.replaceRouteName('Complete');
        return;
      }
      if (replace || !this.structure || model.list[model.step]?.route !== this.currentItem?.route) {
        this.structure = { ...model };
        await this.$onboarding.updateStructure(this.structure);
        if(this.currentRoute !== this.$route.name) {
          this.changeRoute = true;
          await this.replaceRouteName(this.currentRoute, null, this.$route.query);
        }

        this.structure.error = false;
        this.changeRoute = false;
      }

      await sleep(200);
      this.ready = true;
      this.$unblock();
    },

    async initialize() {
      await this.$onboarding.checkCustomStep();
      await this.reset();

      !!this.structure?.model && await this.createProxyController();

      trackGtmEvent({ 'event': 'onboarding_start', 'SiteId': this.site });
      trackSegment('onboarding_start');

      // without-token 으로 최초 전달받은 데이터로만 MandatoryPersonal 체크가 진행되므로 여기서 저장 처리
      console.log('setMandatoryPersonal');
      this.$store.commit('env/setMandatoryPersonal', this.causes?.[0] === CAUSE_TYPES.PersonalDetailNotEnough);
    },
    /**
     * client와의 연동을 위한 message listener
     * @param e
     */
    clientMessageListener(e) {
      if (e?.data && e.data.appMessage) {
        const ownCameraPermission = e.data.appMessage.RESPONSE_PERMISSION_CAMERA?.toLocaleString();
        console.log('%cclientMessageListener', 'color:yellow', e.data);
        if (ownCameraPermission !== null && ownCameraPermission === 'false') {
          this.$onboarding.redirectAuthCamera();
        }
      }
    },
  },
  async mounted() {
    console.log('%cadd clientMessageListener', 'color:blue');
    window.addEventListener('message', this.clientMessageListener);
    await this.$onboarding.setScope(this);
    await this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[onboarding-progress] { .pb(80);
  .desc { .c(@c-text-desc); .fs(16, 24); .medium();
    &.lg { .fs(16, 24); .c(#9f9f9f); }
    &.mlg { .fs(20, 28); .medium(); .c(#bfbfbf); }
    &.xlg { .fs(24, 32); .medium(); .c(white); }
  }
  [progress-button] { .mt(55); }
}
[drop-list] {
  > .list-holder .list-box .item.active { .bgc(); }
}
</style>